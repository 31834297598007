import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route, UrlSegment, CanActivateFn, CanMatchFn, UrlTree } from '@angular/router';
import { getPathToRoute } from '@mca/auth/util';
import { MessageService } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { MenuAccessService } from '../services/menu-access.service';

const verifyAccess = (activatedRoute: ActivatedRouteSnapshot | string, route?: Route): true | UrlTree => {
  const authService = inject(AuthService);
  const accessService = inject(MenuAccessService);
  const messageService = inject(MessageService);
  const router = inject(Router);
  if (!authService.isLoggedIn()) {
    return true;
  }
  const path = typeof activatedRoute === 'string' ? activatedRoute : getPathToRoute(activatedRoute, true);
  const hasAccess = accessService.checkMenuRouteAccess(path);
  if (hasAccess) {
    return true;
  }
  if (route?.path) {
    return verifyAccess(route.path);
  }
  messageService.add({ severity: 'error', summary: 'Page access forbidden', detail: 'Redirected to Home page' });
  return router.createUrlTree(['/']);
};

const segmentsToRoutePath = (segments: UrlSegment[]) => {
  return segments.reduce((path, currentSegment) => `${path}/${currentSegment.path}`, '');
};

export const menuGuardActivate: CanActivateFn = (route: ActivatedRouteSnapshot | string) => {
  return verifyAccess(route);
};

export const menuGuardMatch: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);
  const navigation = router.getCurrentNavigation();
  const path = navigation ? navigation.extractedUrl.toString() : segmentsToRoutePath(segments);
  return verifyAccess(path, route);
};
