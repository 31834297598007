import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { Role, UserLoginInfo, UserReferenceService } from '@mca/user/api';
import { RxState } from '@rx-angular/state';
import { map, Observable } from 'rxjs';
import { Permission, PermissionNames, RoleNames } from '../entities/permission';
import { httpUserPermissions } from '../infrastructure/auth-http-points';
import { AuthService } from './auth.service';
import { PermissionsFilterService } from './permissions-filter.service';
import { PageService } from '@mca/shared/domain';

type RoleIds = {
  [key in RoleNames]: number;
};

interface State {
  roleIds: RoleIds;
  missingPermissions: Set<PermissionNames>;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends RxState<State> {
  private usersService = inject(UserReferenceService);
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private permissionsFilterService = inject(PermissionsFilterService);
  private pageService = inject(PageService);

  get userRoles() {
    return this.authService.currentUser()?.roles ?? [];
  }

  get userPermissions() {
    return this.authService.currentUser()?.permissions ?? [];
  }

  constructor() {
    super();
    this.set({ roleIds: {} as RoleIds, missingPermissions: new Set() });
    this.connect(
      'roleIds',
      this.usersService.roles$.pipe(
        map(roles => roles.reduce((acc: RoleIds, role: Role) => Object.assign(acc, { [role.property_name]: role.id }), {} as RoleIds)),
      ),
    );
    this.hold(this.pageService.loaded$, () => {
      const missingPermissions = this.get('missingPermissions');
      if (missingPermissions.size) {
        console.log('Skipped permissions: ' + [...missingPermissions].join(', '));
        missingPermissions.clear();
      }
    });
  }

  getRoleId(role: RoleNames) {
    return this.get('roleIds')[role];
  }

  hasRole(
    role: RoleNames,
    user?: {
      roles: number[];
    },
  ): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    const roleId = this.getRoleId(role);
    return roles.some(v => v === roleId);
  }

  hasPermission(value?: PermissionNames | PermissionNames[], user?: UserLoginInfo) {
    if (!value || !value.length) {
      return true;
    }
    const userPermissions = user ? user.permissions ?? [] : this.userPermissions;
    let permissions: PermissionNames[] = Array.isArray(value) ? value : [value];
    user ??= this.authService.currentUser();
    if (user) {
      permissions = this.permissionsFilterService.filterUserPermissions(user, permissions);
    }
    const hasPermission = permissions.some(permission => userPermissions.some(userPermission => userPermission === permission));
    if (!hasPermission) {
      permissions.forEach(p => this.get('missingPermissions').add(p));
    }
    return hasPermission;
  }

  rolesAny(
    roles: RoleNames[],
    user?: {
      roles: number[];
    },
  ): boolean {
    const userRoles = user ? user.roles ?? [] : this.userRoles;
    const roleIds = roles.map(role => this.getRoleId(role));
    return userRoles.some(v => roleIds.includes(v));
  }

  isAdmin(): boolean {
    return this.hasRole('role_admin');
  }

  isAccounting(): boolean {
    return this.hasRole('role_accounting');
  }

  userIsLender() {
    if (this.userRoles.length !== 2) {
      return false;
    }
    return this.hasRole('role_mca_user') && this.hasRole('role_Lender');
  }

  userIsCollateralProvicer() {
    if (this.userRoles.length !== 2) {
      return false;
    }
    return this.hasRole('role_mca_user') && this.hasRole('role_collateral_provider');
  }

  userIsWFDashboard() {
    if (this.userRoles.length !== 2) {
      return false;
    }
    return (
      this.userRoles.some(v => v === this.getRoleId('role_mca_user')) &&
      this.userRoles.some(v => v === this.getRoleId('role_mcawfdashboard'))
    );
  }

  canDelete(): boolean {
    if (!this.userRoles.some(v => v === this.getRoleId('role_mca_user'))) {
      return true;
    }
    if (this.userRoles.find(v => v === this.getRoleId('role_dataentry'))) {
      return false;
    }
    if (this.userRoles.length !== 2) {
      return true;
    }
    return (
      !this.userRoles.some(v => v === this.getRoleId('role_collection')) &&
      !this.userRoles.some(v => v === this.getRoleId('role_iso_relations')) &&
      !this.userRoles.some(v => v === this.getRoleId('role_accounting_extern'))
    );
  }

  sameUserName(name: string): boolean {
    return this.authService.currentUser()?.userid === name;
  }

  sameUserId(userId: number): boolean {
    return this.authService.currentUser()?.id === userId;
  }

  extrnAcct(): boolean {
    return this.isRoleOnly(this.getRoleId('role_accounting_extern'));
  }

  dataEntry(): boolean {
    return this.isRoleOnly(this.getRoleId('role_dataentry'));
  }

  isIsoRelOnly(): boolean {
    return this.isRoleOnly(this.getRoleId('role_iso_relations'));
  }

  isRoleOnly(role: number): boolean {
    if (this.userRoles.length !== 2) {
      return false;
    }
    return this.userRoles.some(v => v === this.getRoleId('role_mca_user')) && this.userRoles.some(v => v === role);
  }

  isIso(user?: { roles: number[] }): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    return this.hasRole('role_iso', user) && roles.length <= 2;
  }

  isIsoRel(user?: { roles: number[] }): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    return this.hasRole('role_iso_relations', user) && roles.length <= 2;
  }

  isIsoCollection(user?: { roles: number[] }): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    return this.hasRole('role_collection', user) && roles.length <= 2;
  }

  isIsoOrIsoRel(user?: { roles: number[] }): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    return this.rolesAny(['role_iso', 'role_iso_relations'], user) && roles.length <= 2;
  }

  isCommUser(user?: { roles: number[] }): boolean {
    return this.rolesAny(['role_iso', 'role_iso_relations', 'role_commission', 'role_ins_commission', 'role_contract_fee'], user);
  }

  isCollateralProvider(user?: { roles: number[] }): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    return this.hasRole('role_collateral_provider', user) && roles.length <= 2;
  }

  isLoanIssuer(user?: { roles: number[] }): boolean {
    const roles = user ? user.roles ?? [] : this.userRoles;
    return this.hasRole('role_loan_issuer', user) && roles.length <= 2;
  }

  getPermissions(): Observable<Permission[]>;
  getPermissions(id: number): Observable<Permission>;
  getPermissions(id?: number): Observable<Permission | Permission[]> {
    return this.apiService.get(httpUserPermissions(id));
  }

  createPermission(permission: Permission) {
    return this.apiService.post(httpUserPermissions(), permission);
  }

  updatePermission(permission: Permission) {
    return this.apiService.put(httpUserPermissions(permission.id), permission);
  }

  deletePermission(id: number) {
    return this.apiService.delete(httpUserPermissions(id));
  }
}
