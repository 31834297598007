/// <reference lib="webworker" />
/* eslint-disable @typescript-eslint/naming-convention */

export type {};
declare let self: ServiceWorkerGlobalScope;

export const ServiceWorkerCommand = {
  CLEAN_CACHE: 'cleanCache',
};

const pathToRegExp = (path: string) => new RegExp(path.replaceAll('/', '\\/').replaceAll('*', '([^/]+)'));

const SHORTTIME_CACHE_TIMEOUT = 2000;
// for multiple consecutive requests
const URLS_TO_CACHE_SHORTTIME_REGEX = ['api/mca/*/offers'].map(pathToRegExp);

const URLS_TO_CACHE_REGEX = [
  'api/general/venues',
  'api/user', // references users
  'api/mca/*/ar/assignment',
  'api/ar/users',
  'api/mca/reftab',
  'api/mca/attributes/collatportf/names',
  'api/mca/related/*',
  'api/mca/*/attributes/collatportf',
  'api/mca/*/settlement_attributes',
  'api/mca/*/transaction/nextpayment',
  'api/mca/*/collection/reminder/active',
  'api/mca/*/mcarefcompanies',
  'api/mca/*/creditcard',
  'api/mca/*/loadinvestorsparticipation',
  'api/mca/*/workflow',
  'api/mca/*/answers',
  'api/mca/audit/*',
  'api/mca/qarecord/*',
  'api/mca/datamerch/categories',
  'api/transaction/constants',
  'api/offers/template',
  'api/contractloan/paymentperiods',
  'api/contractloan/lineofcredits/venues',
  'api/contractloan/styles',
]
  .map(pathToRegExp)
  .concat(URLS_TO_CACHE_SHORTTIME_REGEX);

const escapePathMapToRegExp = (pathMap: Record<string, string[]>): Map<RegExp, RegExp[]> =>
  new Map(Object.entries(pathMap).map(([key, value]) => [pathToRegExp(key), value.map(pathToRegExp)]));
const CLEANUP_TRIGGERS_REGEXP_MAP = escapePathMapToRegExp({
  'api/user/*/*': ['api/user'],
  'api/ar/users/*': ['api/ar/users'],
  'api/mca/*/ar/*/assignment': ['api/mca/*/ar/assignment'],
  'api/mca/*/offers/*/*': ['api/mca/*/offers'],
  'api/mca/reftab/*/*': ['api/mca/reftab', 'api/mca/reftab/*', 'api/mca/reftab/*/*'],
  'api/mca/reftab/*': ['api/mca/reftab', 'api/mca/reftab/*', 'api/mca/reftab/*/*'],
  'api/mca/*/offers/*': ['api/mca/*/offers'],
  'api/offers/template/*': ['api/offers/template'],
});
const matchCleanupTriggers = (path: string) =>
  Array.from(CLEANUP_TRIGGERS_REGEXP_MAP.entries())
    .filter(([trigger]) => trigger.test(path))
    .map(([_, triggers]) => triggers)
    .flat();

const ongoingRequests = new Map<string, PromiseLike<Response>>();

export const startServiceWorker = (cacheName: string) => {
  const cleanCache = () =>
    caches.delete(cacheName).then(() => {
      console.log('Service Worker: Cache Cleaned');
    });

  const cleanCacheByUrlPath = (path: string | RegExp) =>
    caches.open(cacheName).then(cache =>
      cache.keys().then(keys => {
        const requests =
          typeof path === 'string'
            ? keys.filter(key => new URL(key.url).pathname.startsWith(path))
            : keys.filter(key => new RegExp(path).test(key.url));
        return requests.length ? Promise.all(requests.map(r => cache.delete(r))) : undefined;
      }),
    );

  self.addEventListener('install', () => {
    self.skipWaiting();
  });

  self.addEventListener('activate', event => {
    // clear old caches when cache name changes
    event.waitUntil(
      caches
        .keys()
        .then(cacheKeys => Promise.all(cacheKeys.map(cacheKey => (cacheKey !== cacheName ? caches.delete(cacheKey) : undefined)))),
    );
  });

  self.addEventListener('fetch', event => {
    if (event.request.method !== 'GET') {
      const cleanupsMatched = matchCleanupTriggers(new URL(event.request.url).pathname);
      cleanupsMatched.forEach(m => cleanCacheByUrlPath(m));
    }

    const pathMatched = URLS_TO_CACHE_REGEX.some(regex => regex.test(event.request.url));
    if (!pathMatched) {
      return;
    }

    if (event.request.method !== 'GET') {
      cleanCacheByUrlPath(new URL(event.request.url).pathname);
      return;
    }

    event.respondWith(
      caches.open(cacheName).then(async cache =>
        cache.match(event.request).then(async cachedResponse => {
          if (cachedResponse) {
            return cachedResponse;
          }

          const existingFetchPromise = ongoingRequests.get(event.request.url)?.then(response => response.clone());

          if (existingFetchPromise) {
            return existingFetchPromise;
          }

          const fetchPromise = fetch(event.request);

          fetchPromise
            .then(response => {
              cache.put(event.request, response.clone());
              const isShorttimeCache = URLS_TO_CACHE_SHORTTIME_REGEX.some(regex => regex.test(event.request.url));
              if (isShorttimeCache) {
                setTimeout(() => cleanCacheByUrlPath(new URL(event.request.url).pathname), SHORTTIME_CACHE_TIMEOUT);
              }
            })
            .finally(() => {
              ongoingRequests.delete(event.request.url);
            });

          ongoingRequests.set(event.request.url, fetchPromise);
          return fetchPromise;
        }),
      ),
    );
  });

  self.addEventListener('message', event => {
    if (event.data.command === ServiceWorkerCommand.CLEAN_CACHE) {
      if (event.data.path) {
        event.waitUntil(cleanCacheByUrlPath(event.data.path));
      } else {
        event.waitUntil(cleanCache());
      }
    }
  });

  self.addEventListener('activate', () => {
    console.log('Service Worker: Updated');
  });
};
